import Cookies from 'js-cookie';
import trackEvent from '@99designs/common/utils/platform/trackEvent';
import FullscreenOverlay from './FullscreenOverlay';
import Catfish from './Catfish';
import EmailCaptureForm from './EmailCaptureForm';

export default class CaptureExperiment {
    constructor({ element, id, type, options = {} }) {
        if (!element || this.isLoggedIn() || !!Cookies.get(id)) {
            return;
        }

        this.element = element;
        this.form = this.element.querySelector('[data-email-capture-form]');
        this.id = id;
        this.type = type;
        this.options = options;
        this.success = false;

        this.handleDismiss = this.handleDismiss.bind(this);
        this.handleSeen = this.handleSeen.bind(this);
        this.handleDocumentScroll = this.handleDocumentScroll.bind(this);
        this.handleSuccess = this.handleSuccess.bind(this);

        // Initial conditions
        this.conditions = {
            timeout: false,
            scroll: false,
        };

        // Monitor scroll position
        window.addEventListener('scroll', this.handleDocumentScroll);

        // Set minimum time before capture is shown
        window.setTimeout(() => this.updateConditions('timeout', true), this.getTimeoutCondition());

        if (this.type === 'lightbox') {
            this.setupLightbox();
        } else if (this.type === 'catfish') {
            this.setupCatfish();
        }

        if (this.form) {
            this.initializeEmailCaptureForm();
        }
    }

    show() {
        if (this.type === 'lightbox' && this.isValidScreenSize()) {
            this.fullscreenOverlay._open();
        } else if (this.type === 'catfish' && this.isValidScreenSize()) {
            this.catfish.show();
        }

        window.clearInterval(this.interval);
    }

    isValidScreenSize() {
        const { minScreenSize, maxScreenSize } = this.options;
        const isValidMinScreenSize = minScreenSize ? minScreenSize <= window.innerWidth : true;
        const isValidMaxScreenSize = maxScreenSize ? maxScreenSize >= window.innerWidth : true;

        return isValidMinScreenSize && isValidMaxScreenSize;
    }

    isLoggedIn() {
        // see https://gitlab.com/vistaprint-org/99designs/accounts/-/merge_requests/1025 for details of how this data is set
        return window.accountsUser && window.accountsUser.isLoggedIn;
    }

    handleDismiss() {
        if (this.type === 'catfish') {
            this.setCookie();
        }

        if (!this.form) {
            return;
        }

        if (!this.success) {
            const emailCaptureName = this.form.getAttribute('data-email-capture-name');

            trackEvent('Dismissed Email Capture Form', {
                acquisition_source: 'blog',
                email_capture_name: emailCaptureName,
            });
        }
    }

    handleSeen() {
        if (this.type === 'lightbox') {
            this.setCookie();
        }

        if (!this.form) {
            return;
        }

        const emailCaptureName = this.form.getAttribute('data-email-capture-name');

        trackEvent('Viewed Email Capture Form', {
            acquisition_source: 'blog',
            email_capture_name: emailCaptureName,
        });
    }

    handleSuccess() {
        this.success = true;

        this.setCookie();

        if (this.type === 'lightbox') {
            this.fullscreenOverlay._close();
        }

        if (this.type === 'catfish') {
            this.catfish.hide();
        }
    }

    handleError() {
        // TODO: Handle error scenarios
    }

    handleDocumentScroll() {
        const scrollPos = document.body.scrollTop || document.documentElement.scrollTop;
        const docHeight = document.body.clientHeight - window.innerHeight;

        if (scrollPos / docHeight > this.getScrollCondition()) {
            this.updateConditions('scroll', true);
            window.removeEventListener('scroll', this.handleDocumentScroll);
        }
    }

    initializeEmailCaptureForm() {
        new EmailCaptureForm($(this.form), { onSuccess: this.handleSuccess });
    }

    updateConditions(name, value) {
        this.conditions[name] = value;

        if (Object.keys(this.conditions).every((c) => this.conditions[c] === true)) {
            this.show();
        }
    }

    setCookie() {
        Cookies.set(this.element.getAttribute('data-cookie-id') || this.id, 1, { expires: 365 });
    }

    setupLightbox() {
        this.fullscreenOverlay = new FullscreenOverlay({
            element: document.querySelector(
                '[data-fullscreen-overlay-id="email-capture-lightbox"]'
            ),
            options: {
                fixedViewport: true,
                onOpen: this.handleSeen,
                onClose: this.handleDismiss,
            },
        });
    }

    setupCatfish() {
        this.catfish = new Catfish({
            element: this.element,
            form: this.form,
            options: {
                onShow: this.handleSeen,
                onHide: this.handleDismiss,
            },
        });
    }

    getTimeoutCondition() {
        if (this.options.timeoutCondition) {
            return this.options.timeoutCondition;
        }

        return 10000;
    }

    getScrollCondition() {
        if (this.options.scrollCondition) {
            return this.options.scrollCondition;
        }

        return 0.5;
    }
}
