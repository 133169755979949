import { once } from 'lodash';
import EmailCaptureForm from './components/EmailCaptureForm';
import EmailSubscriptionService from '@99designs/common/utils/platform/emailSubscriptionService';
import trackEvent from '@99designs/common/utils/platform/trackEvent';
import { trackViewportStateForElement } from './utils/trackViewportStateForElement';
import CaptureExperiment from './components/CaptureExperiment';
import '@99designs/common/jquery-plugins/tooltip';

(function ($) {
    const service = new EmailSubscriptionService();

    $(document).on('OptinMonsterOptinSuccess', (event, data) => {
        service
            .subscribe({
                email: data.optin_data.email,
                acquisitionSource: data.campaign,
                subscribeToMarketing: true,
            })
            .catch((jqXHR, textStatus, errorThrown) => {
                throw `failed to subscribe email address: ${textStatus}: ${errorThrown}`;
            });
    });

    $(document).on('OptinMonsterOnShow', (e, { campaign }) => {
        trackEvent('Viewed Email Capture Form', {
            acquisition_source: campaign,
        });
    });

    $(document).on('OptinMonsterOnClose', (e, { campaign }) => {
        trackEvent('Dismissed Email Capture Form', {
            acquisition_source: campaign,
        });
    });
})(jQuery);

document.addEventListener('DOMContentLoaded', () => {
    // Create generic email capture forms
    const $emailCaptureForms = $('[data-email-capture-form]')
        .not('[data-email-capture-name="generic-newsletter-slide-in"]')
        .not('[data-email-capture-name="generic-newsletter-modal"]');

    if ($emailCaptureForms.length) {
        EmailCaptureForm.create($emailCaptureForms);
    }

    const lightboxCapture = document.querySelector('[data-capture="lightbox"]');
    const catfishCapture = document.querySelector('[data-capture="catfish"]');

    if (lightboxCapture) {
        new CaptureExperiment({
            element: lightboxCapture,
            id: 'blog-subscription-lightbox',
            type: 'lightbox',
            options: {
                minScreenSize: lightboxCapture.getAttribute('data-capture-min-screen-size'),
                timeoutCondition: 3000,
                scrollCondition: 0.25,
            },
        });
    }

    if (catfishCapture) {
        new CaptureExperiment({
            element: catfishCapture,
            id: 'blog-subscription-catfish',
            type: 'catfish',
            options: {
                maxScreenSize: catfishCapture.getAttribute('data-capture-max-screen-size'),
                timeoutCondition: 3000,
                scrollCondition: 0.1,
            },
        });
    }

    const subscribeSection = document.querySelector('[data-subscribe-section]');

    if (subscribeSection) {
        trackViewportStateForElement(
            document.querySelector('[data-subscribe-section]'),
            handleSubscribeInViewportChange,
            { padding: 200 }
        );
    }

    jQuery('[data-tooltip]').tooltip({ location: 'up-center' });
});

const trackEventSubscribeVisibleOnce = once(
    trackEvent.bind(this, 'Viewed Email Capture Form', {
        acquisition_source: 'blog',
        email_capture_name: 'generic-newsletter-in-page',
    })
);

function handleSubscribeInViewportChange({ type }) {
    if (type === 'enter') {
        trackEventSubscribeVisibleOnce();
    }
}
