import EmailSubscriptionService from '@99designs/common/utils/platform/emailSubscriptionService';

const RECAPTCHA_VERFIED_ENDPOINT = '/campaigns/xhr/email-subscriptions/subscribe';

export default class EmailCaptureForm {
    static create($elements, options = {}) {
        $elements.each(function (index) {
            new EmailCaptureForm($elements.eq(index), options);
        });
    }

    constructor($element, options = {}) {
        this._$subscribeForm = $element;
        this._$submitButton = this._$subscribeForm.find('[data-email-capture-submit-button]');
        this._$errorMessageContainer = this._$subscribeForm.find('[data-email-capture-error]');
        this._$spinner = null;
        this.options = options;

        this._$subscribeForm.on('submit', (e) => {
            e.preventDefault();

            if (window.grecaptcha) {
                const recaptchaWidgetAttr = 'data-recaptcha-widget';
                const recaptchaWidgetId = e.currentTarget
                    .querySelector(`[${recaptchaWidgetAttr}]`)
                    .getAttribute(recaptchaWidgetAttr);

                this._subscribeEmailWithRecaptcha(recaptchaWidgetId);
            }
        });
    }

    _subscribeEmailWithRecaptcha(recaptchaWidgetId) {
        window.grecaptcha.execute(recaptchaWidgetId);

        const recaptchaResponse = window.grecaptcha.getResponse(recaptchaWidgetId);
        if (!recaptchaResponse) {
            return;
        }

        this._hideError();
        this._showLoading();

        const service = new EmailSubscriptionService();
        const isDesigner = this._getFormFieldValue('is_designer') || false;

        service
            .subscribe({
                acquisitionSource: this._getFormFieldValue('acquisition_source'),
                email: this._getFormFieldValue('email_address'),
                emailCaptureName: this._getEmailCaptureName(),
                subscribeToMarketing: Boolean(this._getFormFieldValue('subscribe_to_marketing')),
                customData: {
                    // Cast to string as the data type in Iterable is a string
                    is_designer: isDesigner.toString(),
                    recaptcha_response: recaptchaResponse,
                },
                emailSubscriptionEndpoint: RECAPTCHA_VERFIED_ENDPOINT,
            })
            .then(() => {
                this._hideLoading();

                if (this.options.onSuccess) {
                    this.options.onSuccess();
                } else {
                    this._showSuccess();
                }
            })
            .catch(() => {
                this._hideLoading();
                this._showError();
            });
    }

    _getFormFieldValue(fieldName) {
        const field = this._$subscribeForm.get(0).elements[fieldName];

        if (field && field.type === 'checkbox') {
            return field.checked;
        } else if (field && field.value) {
            return field.value;
        } else {
            return null;
        }
    }

    _getEmailCaptureName() {
        if (this.options.emailCaptureName) {
            return this.options.emailCaptureName;
        } else if (this._$subscribeForm.attr('data-email-capture-name')) {
            return this._$subscribeForm.attr('data-email-capture-name');
        }

        return null;
    }

    _showLoading() {
        const $submitButtonFieldset = this._$subscribeForm.find(
            '[data-email-capture-submit-button-wrapper]'
        );

        this._$submitButton.attr('disabled', 'disabled');
        this._$spinner = $('<span class="spinner subscribe__spinner"></span>');
        this._$spinner.hide().appendTo($submitButtonFieldset).fadeIn('fast');
    }

    _hideLoading() {
        this._$submitButton.removeAttr('disabled');
        this._$spinner.hide().detach();
    }

    _showSuccess() {
        const $subscribeSuccessContainer = this._$subscribeForm.prev(
            '[data-email-capture-success]'
        );

        this._$subscribeForm.addClass('submitted');
        $subscribeSuccessContainer.fadeIn('fast');
        $('html, body').animate({ scrollTop: $subscribeSuccessContainer.offset().top }, 80);
        this._$subscribeForm.remove();
    }

    _showError() {
        this._$errorMessageContainer.show();
    }

    _hideError() {
        this._$errorMessageContainer.hide();
    }
}
