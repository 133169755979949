//
// Copied from https://gitlab.com/vistaprint-org/99designs/start/-/blob/master/assets/js/common/utils/trackViewportStateForElement.js
// Monitor state of element being in the viewport or not and trigger callback
// if state changes
//

const BUFFER_TIME = 300;

let appearedInViewport = false;
let buffer = null;

const checkElementState = (element, onStateChange, { padding = 0 }) => {
    const elDistanceToTop = element.getBoundingClientRect().top;

    if (elDistanceToTop - window.innerHeight > 0 + padding) {
        if (appearedInViewport) {
            appearedInViewport = false;

            onStateChange({
                type: 'leave',
                direction: 'top',
            });
        }
    } else if (elDistanceToTop + element.offsetHeight < 0 - padding) {
        if (appearedInViewport) {
            appearedInViewport = false;

            onStateChange({
                type: 'leave',
                direction: 'bottom',
            });
        }
    } else {
        if (!appearedInViewport) {
            appearedInViewport = true;

            onStateChange({
                type: 'enter',
                direction: elDistanceToTop > 0 ? 'top' : 'bottom',
            });
        }
    }
};

const handleOnScroll = (element, onStateChange, options) => {
    if (!buffer) {
        buffer = setTimeout(() => {
            checkElementState(element, onStateChange, options);
            buffer = null;
        }, BUFFER_TIME);
    }
};

export const trackViewportStateForElement = (element, onStateChange, options = {}) => {
    if (element && onStateChange) {
        window.addEventListener(
            'scroll',
            handleOnScroll.bind(this, element, onStateChange, options)
        );
    }
};
