export default class Catfish {
    constructor({ element, options = {}, form }) {
        this.element = element;
        this.form = form;
        this.options = options;

        this.hide = this.hide.bind(this);
        this.showForm = this.showForm.bind(this);

        const dismissActions = this.element.querySelectorAll('[data-dismiss-action]');

        for (let i = 0; i < dismissActions.length; i++) {
            dismissActions[i].addEventListener('click', this.hide);
        }

        this.element
            .querySelector('[data-email-capture-show-form-button]')
            .addEventListener('click', this.showForm);
    }

    show() {
        this.element.setAttribute('data-state', 'show');

        if (this.options.onShow) {
            this.options.onShow();
        }
    }

    hide() {
        this.element.setAttribute('data-state', 'hide');

        if (this.options.onHide) {
            this.options.onHide();
        }
    }

    showForm() {
        this.form.setAttribute('data-form-requested', 'true');
    }
}
