export default class FullscreenOverlay {
    constructor({ element, options = {} }) {
        this.el = element;
        this.options = options;
        this.scrollPosition = null;

        const id = this.el.getAttribute('data-fullscreen-overlay-id');
        const openTriggers = document.querySelectorAll(`[data-fullscreen-overlay-open="${id}"]`);
        const closeTriggers = document.querySelectorAll(`[data-fullscreen-overlay-close="${id}"]`);

        this._handleClose = this._handleClose.bind(this);
        this._handleOpen = this._handleOpen.bind(this);
        this._handleKeyDown = this._handleKeyDown.bind(this);

        for (var i = 0; i < openTriggers.length; i++) {
            openTriggers[i].addEventListener('click', this._handleOpen);
        }

        for (var j = 0; j < closeTriggers.length; j++) {
            closeTriggers[j].addEventListener('click', this._handleClose);
        }
    }

    _handleClose(e) {
        e.preventDefault();

        this._close();
    }

    _handleKeyDown(e) {
        // ESC key
        if (e.keyCode === 27) {
            this._close();
        }
    }

    _handleOpen(e) {
        e.preventDefault();

        this._open();
    }

    _close() {
        document.body.removeEventListener('keydown', this._handleKeyDown, { passive: true });

        this.el.classList.remove('fullscreen-overlay--visible');

        setTimeout(() => {
            this.el.removeAttribute('data-fullscreen-overlay-active');
            document.body.removeAttribute('data-disable-scroll');
        }, 300);

        if (this.options.onClose) {
            this.options.onClose();
        }

        if (this.options.fixedViewport) {
            document.body.removeAttribute('data-fixed-viewport-active');
            document.body.scrollTop = this.scrollPosition;
        }
    }

    _open() {
        document.body.addEventListener('keydown', this._handleKeyDown, { passive: true });

        this.el.setAttribute('data-fullscreen-overlay-active', true);
        document.body.setAttribute('data-disable-scroll', true);

        this.el.classList.add('fullscreen-overlay--visible');

        if (this.options.onOpen) {
            this.options.onOpen();
        }

        if (this.options.fixedViewport) {
            this.scrollPosition = document.body.scrollTop;
            document.body.setAttribute('data-fixed-viewport-active', true);
        }
    }
}
